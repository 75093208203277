// required modules
import Model from '@/models/Model'

/**
 * This class describes a McLibrary model.
 *
 * @class McLibrary
 */
export default class McLibraryModel extends Model {

  /**
   * Define the McLibrary resource.
   *
   * @return {String} McLibrary endpoint name.
   */
  static resource() {
    return 'library'
  }
}