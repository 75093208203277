//
import McLibraryService from '@/services/api/MiningCompany/McLibrary'
//import McLibraryVideosService from '@/services/api/MiningCompany/McLibraryVideos'
import RulesService from '@/services/rules'
//
export default {
  name: 'v-view-library',
  computed: {

    /**
     * 
     */
    folderPaths() {
      return this.mcLibraries.path.split('/')
    },
  },
  data() {
    return {
      busy: {
        create: false,
        delete: false,
        listed: false,
        update: false,   
      },
      dialogs: {
        create: false,
        delete: false,
        folder: false,
        update: false,
      },
      filters: {
        folderPath: ['root', ''],
      },
      mcLibraryCreate: {
        subfolder: '',
      },
      mcLibraryFolder: {},
      mcLibraryDelete: {},
      mcLibraryStored: {},
      mcLibraryUpdate: {},
      mcLibraries: {
        documents: [],
        folders: [],
        name: 'root',
        path: 'root/',
      },
      isLink: false,
      links: false,
      title: null,
    }
  },
  methods: {

    /**
     * 
     */
    handleBreadcrumbClick(item) {
      
      //
      const limit = this.filters.folderPath.findIndex(e => e === item)

      //
      this.filters.folderPath = [
        ...this.filters.folderPath.slice(0, limit + 1),
        "",
      ]
    },

    /**
     * 
     */
    handleCreateReset() {
      this.mcLibraryCreate = McLibraryService.toCreate()
      //this.mcLibraryVideoCreate = McLibraryVideosService.toCreate()
      this.mcLibraryCreate.folderPath = this.filters.folderPath.join('/')
    },

    /**
     * 
     */
    async handleCreateSubmit() {
      this.busy.create = true
      if(this.mcLibraryCreate.subfolder === '') this.mcLibraryCreate.folderPath = this.mcLibraries.path
      await McLibraryService.create(this.mcLibraryCreate).then(this.handleCreateSuccess).then(this.handleReset).catch(this.handleError)
      //if(this.isLink == true) await McLibraryVideosService.create(this.mcLibraryVideoCreate).then(this.handleCreateSuccess).then(this.handleReset).catch(this.handleError)
      this.busy.create = false
      this.handleCreateReset()
      
    },

    /**
     * 
     */
    handleCreateSuccess(response) {
      McLibraryService.translate({ response }).then(this.$toast.success)
      this.dialogs.create  = false
    },

    /**
     * 
     */
    handleDeleteClick(mcLibrary) {
      this.dialogs.delete   = true
      this.mcLibraryDelete  = mcLibrary
    },
    
    /**
     * 
     * 
     */

    /**
     * 
     */
     async handleDeleteSubmit() {
      this.busy.delete = true
      //if(this.mcLibraryDelete.content) await McLibraryVideosService.delete(this.mcLibraryDelete).then(this.handleDeleteSuccess).then(this.handleReset).catch(this.handleError)
      //else 
      await McLibraryService.delete(this.mcLibraryDelete).then(this.handleDeleteSuccess).then(this.handleReset).catch(this.handleError)
      this.busy.delete = false
    },
    /**
     * 
     */
    handleDeleteSuccess(response) {
      //if(this.mcLibraryDelete.content) McLibraryVideosService.translate({ response }).then(this.$toast.success)
      //else 
      McLibraryService.translate({ response }).then(this.$toast.success)
      this.dialogs.delete = false
    },

    

    /**
     * 
     */
    async handleDownloadClick(document) {
      this.busy.listed = true
      await McLibraryService.download(document)
      this.busy.listed = false      
    },

    /**
     * 
     */
    handleFolderClick(folder) {
      this.filters.folderPath = folder.fullPath.split('/')
    },

    /**
     * 
     */
    handleFolderReset() {
      this.mcLibraryFolder = McLibraryService.toFolder()
      //this.mcLibraryVideoFolder = McLibraryVideosService.toFolder()
    },

    /**
     * 
     */
    handleFolderSubmit() {
      this.mcLibraries.folders.push(this.mcLibraryFolder)
      //this.mcLibrariesVideos.folders.push(this.mcLibraryVideoFolder)
    },

    /**
     * 
     */
    handleInfoClick() {
      //document.getElementById("tutorial-link").click()
      this.links=true;
    },

    setVideo(url = null, title = null) {
      this.url = `https://drive.google.com/file/d/${ url }/preview`
      this.title = title
    },

    /**
     * 
     */
    async handleReset() {
      this.busy.listed = true
      this.mcLibraries = await McLibraryService.getByFolder(`${ this.filters.folderPath.join('/') }`)
      //this.mcLibrariesVideos = await McLibraryVideosService.getByFolder(`${ this.filters.folderPath.join('/') }`)
      this.busy.listed = false
      //console.log(this.mcLibrariesVideos)
    },

    /**
     * 
     */
    handleUpdateClick(mcLibrary) {
      this.dialogs.update   = true
      this.mcLibraryUpdate  = { ...mcLibrary, folderPath: this.filters.folderPath.join('/') }
    },

    /**
     * 
     */
    handleUpdateReset() {
      this.mcLibraryUpdate  = { ...this.mcLibraryStored }
    },

    /**
     * 
     */
    async handleUpdateSubmit() {
      this.busy.update = true
      if(this.mcLibraryUpdate.isPublic) this.mcLibraryUpdate.isPublic = '1'
      else this.mcLibraryUpdate.isPublic = '0'
      //if(this.mcLibraryUpdate.content) await McLibraryVideosService.update(this.mcLibraryUpdate).then(this.handleUpdateSuccess).then(this.handleReset).catch(this.handleError)
      //else 
      await McLibraryService.update(this.mcLibraryUpdate).then(this.handleUpdateSuccess).then(this.handleReset).catch(this.handleError)
      this.busy.update = false
    },

    /**
     * 
     */
    handleUpdateSuccess(payload) {
      McLibraryService.translate({ response: payload }).then(this.$toast.success)
      this.dialogs.update = false
    },

    /**
     * 
     */
    handleError(payload) {
      McLibraryService.translate(payload).then(this.$toast.error)
    },
    /**
     * 
     */
    showContent(data){
      if(data == 1) return false
      else return true
    }

  },
  created() {
    this.handleReset()
    this.handleCreateReset()
    this.handleFolderReset()
  },
  props: {

    /**
     * 
     */
    contentRules:{
      type:Array,
      default: () =>[
        RulesService.urlhttps,
      ]
    },
    /**
     * 
     */
    addressRules: {
      type: Array,
      default: () => [
        RulesService.required,
        RulesService.max(255),
      ],
    },

    /**
     * 
     */
    fileRules: {
      type: Array,
      default: () => [
        RulesService.required,
      ],
    },

    /**
     * 
     */
    nameRules: {
      type: Array,
      default: () => [
        RulesService.required,
        RulesService.notEmpty,
      ],
    },

    /**
     * 
     */
    passwordRules: {
      type: Array,
      default: () => [
        RulesService.required,
        RulesService.min(6),
        RulesService.max(20),
      ],
    },

    /**
     * 
     */
    phoneRules: {
      type: Array,
      default: () => [
        RulesService.required,
        RulesService.length(9),
        RulesService.numeric,
      ],
    },

    /**
     * 
     */
    rutRules: {
      type: Array,
      default: () => [
        RulesService.required,
        RulesService.rut,
        RulesService.max(12),
      ],
    },
  },
  watch: {

    /**
     * 
     */
    'filters.folderPath'() {
      this.handleReset()
    },

    /**
     * 
     */
    'mcLibraryCreate.subfolder'() {
      // console.log(this.mcLibraries.path)
      // console.log(this.mcLibraryFolder)
      let lPath = this.mcLibraries.path
      let fName = this.mcLibraryCreate.subfolder
      let fPath = fName.split(' ').join('_').toLowerCase()
      this.mcLibraryCreate.folderPath = `${ lPath }${ fPath }/`
      // this.mcLibraryFolder.path = `/${ fPath }`
      // this.mcLibraryFolder.fullPath = `${ lPath }${ fPath }`
    },
  },
}